import {action, computed, observable} from 'mobx';
import config from "../../config/main.config";
import {
    getTokenFromLocalStorage,
    getUserFromLocalStorage,
    removeTokenFromStorage,
    setTokenLocalStorage,
    setUserToLocalStorage
} from "../../helper/util";

class UserStore {
    // logged in user
    @observable user = null;

    @observable loading = false;
    @observable error = false;
    @observable message = {}

    @computed get userFromServer() {
        if (this.user === null) {
            if (getUserFromLocalStorage() !== null && typeof getUserFromLocalStorage() !== "undefined") {
                this.user = getUserFromLocalStorage();
            }
        }
        return this.user;
    }

    @computed get isAdmin() {
        if (this.user === null) {
            if (getUserFromLocalStorage() !== null && typeof getUserFromLocalStorage() !== "undefined") {
                this.user = getUserFromLocalStorage();
            }
        }
        if (this.user != null && this.user.hasOwnProperty("roles")) {
            return this.user.roles.includes("Admin");
        } else {
            return false;
        }

    }

    @computed get isBlogUser() {
        if (this.user === null) {
            if (getUserFromLocalStorage() !== null && typeof getUserFromLocalStorage() !== "undefined") {
                this.user = getUserFromLocalStorage();
            }
        }
        if (this.user != null && this.user.hasOwnProperty("roles")) {
            return this.user.roles.includes("Blog");
        } else {
            return false;
        }

    }

    @computed get isBlogAdmin() {
        if (this.user === null) {
            if (getUserFromLocalStorage() !== null && typeof getUserFromLocalStorage() !== "undefined") {
                this.user = getUserFromLocalStorage();
            }
        }
        if (this.user != null && this.user.hasOwnProperty("roles")) {
            return this.user.roles.includes("BlogAdmin");
        } else {
            return false;
        }

    }

    @action authenticateUser(userToAuthenticate) {
        const token = getTokenFromLocalStorage();
        if (userToAuthenticate === null) {
            alert("Bitte melden Sie sich erneut an.")
        } else {
          return new Promise(res =>  fetch(config.BASE_URL + "users/authenticate", {
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": "Bearer " + token
                },
                body: JSON.stringify(userToAuthenticate)
            })
                .then(response => {
                    if (response.status >= 200 && response.status < 300) {
                        response.json().then(json => {
                            if(json === "Fehler beim Anmelden"){
                                return;
                            }
                            setTokenLocalStorage(json.token);
                            setUserToLocalStorage(json);
                            this.user = json;
                            res(json)
                        });

                    } else {
                        //TODO: Alert?
                        removeTokenFromStorage();
                        this.error = true;
                        this.user = null;
                    }
                })
                .catch(
                    error => {
                      console.log(error)
                        removeTokenFromStorage();
                        this.loading = false;
                        this.error = true;
                        this.user = null;
                        throw error;
                    }
                ));
        }

    }

    @action registerUser(userToRegister) {
        if (userToRegister === null) {
            alert("Bitte melden Sie sich erneut an.")
        } else {
            return new Promise(result => fetch(config.BASE_URL + "users/register", {
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                },
                body: JSON.stringify(userToRegister)
            }).then(response => {
                if (response.status >= 200 && response.status < 300) {
                    response.json().then(json => {
                        result(json)
                    })
                } else {
                    this.error = true;
                    result(null)
                }
            }).catch(
                error => {
                    this.error = true;
                    result(null)
                }));
        }
    }
}

const store = new UserStore();

export default store;