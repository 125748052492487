import React from "react";
import {withTranslation, Trans} from "react-i18next";
import {Button, Grid, Icon, Modal} from "semantic-ui-react";
import Checkbox from "semantic-ui-react/dist/commonjs/modules/Checkbox";
import ReactGA from 'react-ga';
import Cookies from "js-cookie";
import {hotjar} from 'react-hotjar';
import {Link} from "react-router-dom";
import history from "../../helper/browserHistory";
import i18n from "i18next";


const COOKIE_NAME = "acceptCookies";

class CookieBanner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isCookieModalOpen: false,
            performanceCookies: false,
            showConsentBanner: false
        }
    }

    componentDidMount() {
        let cookieValue = Cookies.get('acceptCookies');
        if (typeof cookieValue != "undefined" && cookieValue.length > 0) {
            let cookie = JSON.parse(Cookies.get('acceptCookies'));
            if (typeof cookie != "undefined" &&
                cookie.hasOwnProperty("performance") &&
                cookie.performance === true) {
                ReactGA.initialize('UA-2447971-1');
                hotjar.initialize(1743641, 6);
                this.state.performanceCookies = true;
            } else {
                this.state.showConsentBanner = true;
            }
        } else {
            this.state.showConsentBanner = true;
        }


        history.listen(() => {
            this.setState({})
        });

        if (window.location.hash.length > 0) {
            let url = window.location.hash.replace("#", "");
            history.push(url);
        }
    }

    /*
     * TODO: History listener der bei changes das ausführt:
     *   ReactGA.pageview(link); //link ->this.props.location.pathname
     */

    setCookies() {
        Cookies.set(COOKIE_NAME, JSON.stringify({
            necessary: true,
            performance: this.state.performanceCookies
        }), {expires: 365});
        if (this.state.performanceCookies) {
            //init analytics and hotjar
            ReactGA.initialize('UA-2447971-1');
            hotjar.initialize(1743641, 6);
        }
        this.setState({
            ...this.state,
            showConsentBanner: false,
            isCookieModalOpen: false
        })
    }

    render() {
        const {t} = this.props
        let contentToShow;
        let language = this.props.i18n.language;
        if (this.state.isCookieModalOpen) {
            contentToShow =
                <Modal
                    className={"cookie-modal"}
                    open={this.state.isCookieModalOpen}>
                    <Modal.Header>  {t('nav:titles.cookie')}</Modal.Header>
                    <Modal.Content
                        scrolling>
                        <p>   {t('cookie_consent.modal_text')}</p>
                        <h3>{t('common:cookie_consent.essential_header')}</h3>
                        <p>{t('common:cookie_consent.essential_text')}</p>
                        <Link to={`/${language}/data-privacy`}
                              onClick={() => this.setState({...this.state, isCookieModalOpen: false})}>
                            {t('common:cookie_consent.view_cookies').toUpperCase()}
                        </Link><br/>
                        <Checkbox slider checked={true} readOnly/>
                        <h3>{t('common:cookie_consent.performance_header')}</h3>
                        <p>{t('common:cookie_consent.performance_text')}</p>
                        <Link to={`/${language}/data-privacy`}
                              onClick={() => this.setState({...this.state, isCookieModalOpen: false})}>
                            {t('common:cookie_consent.view_cookies').toUpperCase()}
                        </Link><br/>
                        <Checkbox
                            slider checked={this.state.performanceCookies}
                            onChange={() => this.setState({
                                ...this.state,
                                performanceCookies: !(this.state.performanceCookies)
                            })}
                        />
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            floated='left'
                            color='black'
                            basic
                            onClick={() => {
                                this.setState({...this.state, isCookieModalOpen: false});
                            }}>
                            <Icon name='arrow left'/>{t('common:common.back')}
                        </Button>
                        <Button
                            color='black'
                            basic
                            onClick={this.setCookies.bind(this)}>
                            {t('common:common.save')}
                        </Button>
                        <Button
                            className={"glw-button"}
                            onClick={() => {
                                //set cookie
                                Cookies.set(COOKIE_NAME, JSON.stringify(
                                    {
                                        necessary: true,
                                        performance: true
                                    }), {expires: 365});
                                //init analytics and hotjar
                                ReactGA.initialize('UA-2447971-1');
                                hotjar.initialize(1743641, 6);
                                //hide banner
                                this.setState({
                                    ...this.state,
                                    showConsentBanner: false,
                                    isCookieModalOpen: false
                                })
                            }}>
                            {t('common:cookie_consent.accept_all')}
                        </Button>
                    </Modal.Actions>
                </Modal>
        } else {
            contentToShow =
                <div
                    className={this.state.showConsentBanner ? "cookie_container" : "hide_cookie_div"}>
                    <Grid>
                        <Grid.Row columns={2}>
                            <Grid.Column computer={12} tablet={10} mobile={16}>
                                <Trans i18nKey={'common:cookie_consent.text'}
                                       components={[<a href={`/${i18n.language.replace("_", "-")}/data-privacy`}
                                                       key={0}/>]}/>
                            </Grid.Column>
                            <Grid.Column
                                computer={4} tablet={6} mobile={16}
                                textAlign={"right"}>
                                <Button
                                    style={{
                                        background: "#bfbfbf",
                                        color: "#bc1c53",
                                        fontSize: "13px",
                                        textDecoration: "underline"
                                    }}
                                    onClick={() => {
                                        //open modal
                                        this.setState({
                                            ...this.state,
                                            isCookieModalOpen: true
                                        })
                                    }}>
                                    {t('common:cookie_consent.cookie_options')}
                                </Button>
                                <Button
                                    className={"glw-button"}
                                    onClick={() => {
                                        //set cookie
                                        Cookies.set(COOKIE_NAME, JSON.stringify({
                                            necessary: true,
                                            performance: true
                                        }), {expires: 365});
                                        //init analytics and hotjar
                                        ReactGA.initialize('UA-2447971-1');
                                        hotjar.initialize(1743641, 6);
                                        //hide banner
                                        this.setState({...this.state, showConsentBanner: false})
                                    }}>
                                    {t('common:cookie_consent.button')}
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
        }
        return (contentToShow)
    }
}

export default withTranslation(['common'])(CookieBanner)
