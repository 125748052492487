import React from "react";
import {withTranslation} from "react-i18next";
import {NavLink} from "react-router-dom";
import i18n from "../../i18n";
import userStore from "../userManagement/userStore";
import {removeTokenFromStorage} from "../../helper/util";


const AdminNavigation = (props) => {
    const {t} = props;

    return (
        <div className={'row centered'} style={{backgroundColor: 'lightblue', padding: 0}}>
            <div className={'column nav-item-container'}>
                <NavLink to={`/${i18n.language}/admin/termine`}>Veranstaltungen</NavLink>
            </div>
            <div className={'column nav-item-container'}>
                <NavLink to={`/${i18n.language}/admin/user-management`}>Benutzerverwaltung</NavLink>
            </div>
            <div className={'column nav-item-container'}>
                {userStore.userFromServer !== null ?
                    <NavLink
                        onClick={() => {
                            removeTokenFromStorage();
                            userStore.user = null;
                        }}
                        to={`/${i18n.language.replace('_', '-')}/`}>
                        {t('nav:titles.logout')}
                    </NavLink>
                    :
                    <NavLink
                        to={`/${i18n.language}/login`}>
                        {t('nav:titles.login')}
                    </NavLink>
                }
            </div>
        </div>
    )
}

export default withTranslation()(AdminNavigation)