import './styles/_all.scss';
import "dayjs/locale/de";
import React, {Suspense} from "react"
import {hydrate} from "react-dom"
import Button from "semantic-ui-react/dist/commonjs/elements/Button";

import App from "./components/App"
import history from "./helper/browserHistory"
import {Router} from "react-router-dom";
import {ServerDataProvider} from "./state/serverDataContext";
import dayjs from "dayjs";
let SERVER_DATA = {};

dayjs.locale("de");

if (typeof window !== "undefined") {
    SERVER_DATA = window.__SERVER_DATA__;
}

export const main = () => {
    return (
        hydrate(
            <Suspense
                fallback={<Button style={{margin: "auto", display: "contents"}} content={"Loading..."} loading basic
                                  size={"massive"}/>}>
                <Router history={history}>
                    <ServerDataProvider value={SERVER_DATA}>
                        <App/>
                    </ServerDataProvider>
                </Router>
            </Suspense>,
            document.getElementById('root')
        )
    );
};




