
export default {

    LANGUAGES: [
        {
            language: 'de-DE',
            i18nLang: 'de-DE',
            label: 'DE',
            adj: 'Deutsch',
            regexToSearch: '/'
        },
        {
            language: 'en-UK',
            i18nLang: 'en-UK',
            label: 'EN',
            adj: 'English',
            regexToSearch: /^\/en-UK*/
        }
    ],
    "IMAGE_SIZES": {
        "LARGE": 1920,
        "MEDIUM": 1024,
        "SMALL": 320
    }
}

