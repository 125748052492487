import React from 'react';
import Fade from 'react-reveal/Fade';
import {Icon} from "semantic-ui-react";


export default class ScrollButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            is_visible: false
        };
    }

    componentDidMount() {
        var scrollComponent = this;
        document.addEventListener('scroll', function (e) {
            scrollComponent.toggleVisibility();
        });
    }

    toggleVisibility() {
        if (window.pageYOffset > 150) {
            this.setState({
                is_visible: true
            });
        } else {
            this.setState({
                is_visible: false
            });
        }
    }

    scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    render() {
        return (
            <div className={"scroll_to_top"}>
                <Fade top when={this.state.is_visible}>
                    <div onClick={() => this.scrollToTop()}>
                        <Icon
                            style={{margin:0}}
                            className={"scroll_up_button_image"}
                            circular
                            size={'large'}
                            name={"chevron up"}
                        />
                    </div>
                </Fade>
            </div>
        );
    }
}