import React from "react"
import {Helmet} from "react-helmet-async";
import {withTranslation} from 'react-i18next';
import config from "../config/main.config";


class Meta extends React.Component {
    constructor(props) {
        super(props);
        const {i18n, route} = this.props;
        let hrefLangLinks = [];
        let currAddress = route;
        let pathWithoutLang = currAddress;

        if (typeof route !== "undefined") {
            //get current address
            currAddress = route;
            let languageForURL = "";
            pathWithoutLang = currAddress;

            // if (i18n.language !== "de-DE") {
            languageForURL = i18n.language + "/";
            pathWithoutLang = pathWithoutLang.replace(languageForURL, '');
            //  }

            hrefLangLinks = [
                <link key={"alternate-link-default"} rel="alternate" hrefLang="x-default"
                      href={config.URL + "de-DE" + pathWithoutLang}/>,
                <link key={"alternate-link-de"} rel="alternate" hrefLang="de_DE"
                      href={config.URL + "de-DE" + pathWithoutLang}/>,
                <link key={"alternate-link-en"} rel="alternate" hrefLang="en_EN"
                      href={config.URL + "en-EN" + pathWithoutLang}/>
            ];
        }
        this.state = {
            hrefLangLinks: hrefLangLinks,
            pathWithoutLang: pathWithoutLang
        }
    }

    render() {
        const {i18n} = this.props;

        let htmlLang = "de-DE";
        if (i18n.language !== "de-DE") {
            htmlLang = i18n.language;
        }
        return (
            <Helmet htmlAttributes={{lang: htmlLang}}>
                {this.state.hrefLangLinks}
            </Helmet>
        )
    }
}

export default withTranslation()(Meta)