/**
 * Created by Matijas on 11.04.2017.
 */

import { isDevelop } from "../helper/util";

export default {
    URL: isDevelop() ? `http://localhost:3003/`: "https://calendula-hospiz.de/",
    BASE_URL: isDevelop() ?`http://localhost:3003/api/` : "https://calendula-hospiz.de/api/",
    BASE_URL_IMAGES : isDevelop() ? `http://localhost:3003/images/` : "https://calendula-hospiz.de/images/",
    STORAGE_KEY: "4WfG5jv3",
    USER_STORAGE_KEY: "cjhSopcv0w9",
    BLOG_CATEGORIES: ["it", "coaching", "booking"]
}