import React, {useEffect, useState} from "react";
import {withTranslation} from "react-i18next";
import config from '../../config/main.config'
import {NavLink, useLocation} from "react-router-dom";
import {UseWindowSize, MAX_MOBILE_WIDTH, removeTokenFromStorage} from "../../helper/util";
import userStore from "../userManagement/userStore";
import history from "../../helper/browserHistory";


const Footer = (props) => {
    const location = useLocation();
    const {t, i18n} = props;
    let copyright = `©${new Date().getFullYear()} Triple-A Soft UG (haftungsbeschränkt)`;
    const applicationsWidth = UseWindowSize().width;
    const [bowImageSrc, setBowImageSrc] = useState('/images/bows/Bogen2_Footer_Hospiz_Weiss.svg');

    useEffect(() => {
        if (location.pathname.includes('ehrenamt', 6) || location.pathname.includes('jobs', 6) || location.pathname.includes('donate', 6)) {
            setBowImageSrc('/images/bows/Bogen2_Footer_Hospiz_Gruen.svg');
        } else if (location.pathname.includes('ambulant', 6)) {
            setBowImageSrc('/images/bows/Footer_Bogen_Gelb.svg');
        } else if (location.pathname.length <= 7) {
            setBowImageSrc('/images/bows/Footer_Bogen_LP.svg');
        } else {
            setBowImageSrc('/images/bows/Bogen2_Footer_Hospiz_Weiss.svg');
        }
    }, [location]);

    return (
        <div className={'grid ui footer-container'}>
            <img
                src={bowImageSrc}
                alt={'footer-bow'}
                className={'footer-bow'}/>
            <div
                className={applicationsWidth <= MAX_MOBILE_WIDTH ? 'row centered page-content-wrapper' : 'row page-content-wrapper'}>
                <div className={'four wide computer fourteen wide mobile column'}>
                    <img src={config.BASE_URL_IMAGES + 'Calendula_Schrift_Weiss.svg'} alt={'Calendula'}
                         style={{paddingTop: '85px', paddingBottom: '85px'}}/>
                </div>
            </div>
            <div className={'row centered page-content-wrapper'} style={{marginBottom: '2rem'}}>
                <div className={'four wide computer fourteen wide mobile column'}>
                    <h2 className={'footer-text'} style={{color: 'white'}}>Über Uns</h2>
                    <svg className={'footer-svg-line'} width='100%' height='6'>
                        <line x1='0' y1='0' x2='70%' y2='0' stroke='white' strokeWidth='6'/>
                    </svg>
                    <p className={'footer-text'}><NavLink style={{color: 'white'}}
                                                           to={'/' + i18n.language + '/jobs'}>Jobs</NavLink></p>
                    <p className={'footer-text'}><NavLink style={{color: 'white'}}
                                                          to={'/' + i18n.language + '/downloads'}>Downloads</NavLink></p>
                    <p className={'footer-text'}><NavLink style={{color: 'white'}}
                                                          to={'/' + i18n.language + '/data-privacy'}>Datenschutz</NavLink>

                    </p>
                    <p className={'footer-text'}><NavLink style={{color: 'white'}}
                                                          to={'/' + i18n.language + '/satzung'}>Satzung</NavLink>
                    </p>
                    <p className={'footer-text'}><NavLink style={{color: 'white'}}
                                                          to={'/' + i18n.language + '/imprint'}>Impressum</NavLink>
                    </p>
                </div>
                <div className={'eight wide computer fourteen wide mobile column mobile-footer-container'}>
                    <h2 className={'footer-text'} style={{color: 'white'}}>Wir sind für Sie da</h2>
                    <svg className={'footer-svg-line'} width='100%' height='6'>
                        <line x1='0' y1='0' x2='82%' y2='0' stroke='white' strokeWidth='6'/>
                    </svg>
                    <div className={'footer-middle-item-container'}>
                        <img className={'footer-image'} src={config.BASE_URL_IMAGES + 'calendula.webp'}
                             alt={'Calendula'}/>
                        <span className={'footer-icon-text'}>
                            Hospiz ambulant
                         <p className={'footer-sub-text footer-sub-text-mobile'}>
                        Paradiesstraße 5<br/>
                        88239 Wangen im Allgäu <br/>
                        Tel. <a href={'tel:075227728870'} rel={'noreferrer'}>07522 7728870</a> <br/>
                             <a href={'mailto:info@calendula-hospiz.de'}
                                rel={'noreferrer'}>info@calendula-hospiz.de</a>
                    </p>
                        </span>

                        <img className={'footer-image'} src={config.BASE_URL_IMAGES + 'Hospiz.webp'}
                             alt={'Hospiz'}/>
                        <span className={'footer-icon-text'}>
                        Hospiz stationär
                    <p className={'footer-sub-text'}>
                        Am Engelberg 29 <br/>
                        88239 Wangen im Allgäu <br/>
                        Tel. <a href={'tel:0752297330'} rel={'noreferrer'}>07522 97330</a> <br/>
                        Fax 07522-973329 <br/>
                        <a href={'mailto:post@calendula-hospiz.de'} rel={'noreferrer'}>post@calendula-hospiz.de</a>
                    </p>
                        </span>
                    </div>
                </div>
                <div className={'four wide computer fourteen wide mobile column mobile-footer-container'}>
                    <h2 className={'footer-text'} style={{color: 'white'}}>Unterstützen Sie Uns</h2>
                    <svg className={'footer-svg-line'} width='100%' height='6'>
                        <line x1='0' y1='0' x2='100%' y2='0' stroke='white' strokeWidth='6'/>
                    </svg>
                    <img className={'footer-image'} src={config.BASE_URL_IMAGES + 'Stiftung.webp'}
                         alt={'Stiftung'}/>
                    <span className={'footer-icon-text'}>
                        Calendula Stiftung
                </span>
                    <button className={'button-1'}
                            onClick={_ => history.push('/' + i18n.language + '/donate')}
                            style={{
                                backgroundColor: 'transparent',
                                color: 'white',
                                borderColor: 'white',
                                marginLeft: '57px',
                                marginTop: '0'
                            }}>
                        Jetzt Spenden
                    </button>
                </div>
            </div>
            <div className={'row centered'} style={applicationsWidth <= MAX_MOBILE_WIDTH ? {
                backgroundColor: "#F8F8F8",
                marginBottom: '50px'
            } : {backgroundColor: "#F8F8F8"}}>
                {copyright}
                <div className={'two wide computer five wide mobile column'} style={{textAlign: 'center'}}>
                    {userStore.userFromServer !== null ?
                        <NavLink className={'footer-nav-item'}
                                 position='right'
                                 onClick={() => {
                                     removeTokenFromStorage();
                                     userStore.user = null;
                                 }}
                                 exact
                                 to={`/${i18n.language.replace('_', '-')}/`}>
                            {t('nav:titles.logout')}
                        </NavLink>
                        :
                        <NavLink className={'footer-nav-item'}
                                 exact
                                 to={`/${i18n.language}/login`}>
                            {t('nav:titles.login')}
                        </NavLink>}
                </div>
            </div>
        </div>
    );
}


/*


*/

export default withTranslation(['nav'])(Footer)
