import React, {useEffect} from "react"

import Navigation from "../components/navigation/Navigation"
import Footer from "../components/footer/Footer";
import Meta from "./Meta";
import {renderRoutes} from "react-router-config";
import allRoutes from "../../shared/routes"
import {HelmetProvider} from "react-helmet-async";
import {useLocation} from "react-router-dom"
import CookieBanner from "./cookieBanner/CookieBanner";
import ScrollButton from "./scrollUp/ScrollUp";
import SideBar from "./sideBar/SideBar";

const App = () => {
    const location = useLocation();
    let r = null;
    if (typeof document !== 'undefined') {
        r = document.querySelector(':root');
    }

    useEffect(_ => {
        if (r !== null) {
            switch (true) {

                case location.pathname.includes('stationär'):
                    r.style.setProperty('--header-color', 'var(--blau1)');
                    r.style.setProperty('--button-color', 'var(--blau1)');
                    break;
                case location.pathname.includes('ambulant'):
                    r.style.setProperty('--header-color', 'var(--gelb)');
                    r.style.setProperty('--button-color', 'var(--gelb)');
                    break;
                case location.pathname.includes('stiftung'):
                    r.style.setProperty('--header-color', 'var(--gruen)');
                    r.style.setProperty('--button-color', 'var(--gruen)');
                    break;
                default:
                    r.style.setProperty('--header-color', 'var(--blau1)');
                    r.style.setProperty('--button-color', 'var(--blau1)');
                    break;
            }
        }
    }, [location.pathname]);

    return (
        <HelmetProvider>
            <Meta route={location.pathname}/>
            <Navigation/>
            {renderRoutes(allRoutes)}
            <div style={{position:'relative'}}>
                <Footer/>
            </div>
            <SideBar/>
            <CookieBanner/>
            <ScrollButton/>
        </HelmetProvider>
    );


};

export default (App)