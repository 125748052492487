import React from "react";
import {withTranslation} from "react-i18next";

class LoaderComponent extends React.Component {
    render() {
        const {t} = this.props;
        return t("loading");
    }
}

export default withTranslation()(LoaderComponent);